import { ISelectedFolder } from 'shared/interfaces';

export const initialState: ISelectedFolder = {
  text: '',
};

const selectedFolder = (state: ISelectedFolder = initialState, action: any) => {
  switch (action.type) {
    case 'SET_SELECTED_FOLDER':
      return action.payload;
    default:
      return state;
  }
};

export default selectedFolder;
