import React from 'react';
import { Provider } from 'react-redux';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';
import { msalConfig, graphLoginRequest } from 'authConfig';
import { ConfirmProvider } from 'material-ui-confirm';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import Router from 'router';

import Store from 'store';

import 'App.css';
import { StylesProvider } from '@material-ui/core/styles';
import { SearchModeProvider } from 'shared/contexts/search-mode-context';
import { reactPlugin } from './services/appinsights/AppInsights';

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={msalInstance}>
        <Provider store={Store}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={graphLoginRequest}
          >
            <ConfirmProvider>
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <StylesProvider injectFirst>
                  <span className="global-variables">
                    <SearchModeProvider>
                      <Router />
                    </SearchModeProvider>
                  </span>
                </StylesProvider>
              </BrowserRouter>
            </ConfirmProvider>
          </MsalAuthenticationTemplate>
        </Provider>
      </MsalProvider>
    </AppInsightsContext.Provider>
  );
}

export default App;
