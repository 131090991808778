import { addServiceConfig, servicesConfig } from 'authConfig';
import SearchModeType from 'shared/enums/SearchModeTypes';

export interface ModeDetails {
  id: string;
  label?: string;
  version?: string;
  type: SearchModeType;
  [key: string]: string | SearchModeType | undefined;
}

const validModeTypes = new Set<string>(Object.values(SearchModeType));

const useAvailableModes = (): ModeDetails[] => {
  const appModesEnv = process.env.REACT_APP_MODES;

  if (!appModesEnv) {
    return [
      { id: 'query', type: SearchModeType.Query },
      {
        id: 'chatv1', type: SearchModeType.Chat, label: 'Chat v1', version: '1',
      },
      {
        id: 'chatv2', type: SearchModeType.Chat, label: 'Chat v2', version: '2',
      },
    ];
  }

  const parseModes = (modesString: string): ModeDetails[] => {
    const modePattern = /(\w+)(\[(.*?)\])?/g;
    const modes: ModeDetails[] = [];
    let match = modePattern.exec(modesString);

    while (match !== null) {
      const type = match[1].trim();
      const params = match[3];
      const details: ModeDetails = { id: `${type}-${modes.length + 1}` } as ModeDetails;

      let isValidMode = false;
      // Handle legacy modes and valid modes
      if (type === 'query') {
        details.type = SearchModeType.Query;
        details.label = 'Query';
        details.version = '1';
        isValidMode = true;
      } else if (type === 'chatv2' || type === 'chatv2 (alpha)') {
        details.type = SearchModeType.Chat;
        details.label = 'Chat v2 (alpha)';
        details.version = '2';
        isValidMode = true;
      } else if (type === 'chat') {
        details.type = SearchModeType.Chat;
        details.label = 'Chat';
        details.version = '1';
        isValidMode = true;
      } else if (validModeTypes.has(type)) {
        details.type = type as SearchModeType;
        isValidMode = true;
      }

      if (isValidMode) {
        if (params) {
          const paramPattern = /(\w+)='([^']+)'/g;
          let paramMatch = paramPattern.exec(params);
          while (paramMatch !== null) {
            const key = paramMatch[1].trim();
            const value = paramMatch[2].trim();
            details[key] = value;

            paramMatch = paramPattern.exec(params);
          }

          const requiredProps = ['label'];
          requiredProps.forEach((prop) => {
            if (!details[prop]) {
              throw new Error(`Missing required property '${prop}' in mode '${type}'. Ensure '${prop}' is present.`);
            }
          });
        }

        if (details.type === SearchModeType.CustomChat && details.endpoint) {
          addServiceConfig(details.id, details.endpoint);
        }

        modes.push(details);
      }
      match = modePattern.exec(modesString);
    }

    return modes;
  };

  return parseModes(appModesEnv);
};

export default useAvailableModes;
