/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import './index.css';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/styles/withStyles';

interface props {
    inputValue: any;
    inputType: 'text' | 'textarea';
    placeholderText?: string;
    onInputChange: (e: any, value: string) => void;
}

const FormattedTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

export default function DoubleClickInput({
  inputValue, inputType, placeholderText, onInputChange,
} : props) {
  const [toggle, setToggle] = useState(true);
  const [text, setText] = useState('');

  const toggleInput = () => {
    setText(inputValue);
    setToggle(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      onInputChange(event, text);
      setToggle(true);
    }
    if (event.key === 'Escape') {
      setToggle(true);
    }
  };

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setToggle(true);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const handleChange = (event: any) => {
    setText(event.target.value);
    // onInputChange(event, event.target.value);
  };

  const renderInput = (type: string) => {
    switch (type) {
      case 'text':
        return <input type="text" value={text} onChange={handleChange} onKeyPress={handleKeyPress} />;

      case 'textarea':
        return <textarea value={text} onChange={handleChange} onKeyPress={handleKeyPress} />;

      default:
        return '';
    }
  };

  return (
    <div className="doubleClickInput">
      {toggle ? (
        <FormattedTooltip enterDelay={2000} arrow placement="top" title="Double click to edit">
          <span onDoubleClick={toggleInput}>
            {inputValue}{
          ((!inputValue || inputValue === '') && placeholderText !== '')
          && (<i>{placeholderText}</i>)
        }
          </span>
        </FormattedTooltip>
      ) : (renderInput(inputType))}
    </div>
  );
}

DoubleClickInput.defaultProps = {
  placeholderText: '',
};
