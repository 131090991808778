import useAvailableModes, { ModeDetails } from 'hooks/useAvailableModes';
import React, { createContext, useState } from 'react';
import SearchModeType from 'shared/enums/SearchModeTypes';

interface SearchModeContextProps {
  mode: ModeDetails;
  setMode: (mode: ModeDetails) => void;
  availableModes: ModeDetails[];
}

const defaultModeEnv = process.env.REACT_APP_DEFAULT_MODE as string;

export const SearchModeContext = createContext<SearchModeContextProps>({
  mode: { id: 'query', type: SearchModeType.Query }, // default fallback mode
  setMode: () => {},
  availableModes: [],
});

interface SearchModeProviderProps {
  children: React.ReactNode;
}

export const SearchModeProvider = ({ children }: SearchModeProviderProps) => {
  const availableModes = useAvailableModes();
  const defaultMode = availableModes.find((mode) => mode.id === defaultModeEnv) || availableModes[0];
  const [mode, setMode] = useState<ModeDetails>(defaultMode);

  return (
    <SearchModeContext.Provider value={{ mode, setMode, availableModes }}>
      {children}
    </SearchModeContext.Provider>
  );
};
