import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IAppState, IProjectItem } from 'shared/interfaces';
import { FaAngleRight, FaPlus, FaUserFriends } from 'react-icons/fa';
import DoubleClickInput from 'shared/components/doubleclick-input';

interface props {
  addProject: (name: string) => void;
  editProject?: (project: IProjectItem) => void;
  selectProject: (selectedProject: IProjectItem) => void;
  innerRef: any;
}

export default function ProjectsList({
  addProject, editProject, selectProject, innerRef,
}:props) {
  const projects = useSelector((state: IAppState) => state.projects);
  const [addNewProjectName, setAddNewProjectName] = useState('');

  const handleScrollToSelf = (e: any) => {
    e.stopPropagation();
    innerRef.current.scrollIntoView({ behavior: 'smooth', inline: 'end' });
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { value } = e.target;
    setAddNewProjectName(value);
  };

  const handleProjectNameChange = (event: any, value: string, project: IProjectItem) => {
    const editProjectItem : IProjectItem = {
      ProjectID: project.ProjectID,
      ProjectName: value,
      Comment: project.Comment,
      Collection: project.Collection,
      SavedQueries: project.SavedQueries,
      NoOfCollection: project.NoOfCollection,
      NoOfSavedQueries: project.NoOfSavedQueries,
      Shared: project.Shared,
    };
    if (editProject) editProject(editProjectItem);
  };

  const handleRowSelect = (selectedProject : IProjectItem) => {
    selectProject(selectedProject);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    addProject(addNewProjectName);
    setAddNewProjectName('');
  };

  return (
    <div className="projects-list column" ref={innerRef}>
      <div className="list" onClick={(event) => handleScrollToSelf(event)}>
        {projects && projects.Project.map((item) => (
          <div
            key={item.ProjectID}
            className={`list-item ${item.ProjectID === projects.UIselectedProject?.ProjectID ? 'active' : ''}`}
            onClick={() => handleRowSelect(item)}
          >
            <div className="list-item-content">
              <DoubleClickInput inputType="text" inputValue={item.ProjectName} onInputChange={(e, value) => handleProjectNameChange(e, value, item)} />
              {item.Shared && (<FaUserFriends className="shared-right" />)}
            </div>
            <FaAngleRight className="angle-right" />
          </div>
        ))}
      </div>
      <div className="add-project add-item">
        <form className="single-input" onSubmit={handleSubmit}>
          <input type="text" placeholder="add new project" value={addNewProjectName} onChange={(e) => handleInputChange(e)} />
          <button type="submit" className="plus" aria-label="add project">
            <FaPlus size={20} />
          </button>
        </form>
      </div>
    </div>
  );
}

ProjectsList.defaultProps = {
  editProject: undefined,
};
