import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IAppState, IProjectCollection } from 'shared/interfaces';
import { FaAngleRight, FaPlus } from 'react-icons/fa';
import DoubleClickInput from 'shared/components/doubleclick-input';

interface props {
  addCollection: (name: string) => void;
  editCollection?: (project: IProjectCollection) => void;
  selectCollection: (clickedCollection : IProjectCollection) => void;
  innerRef: any;
}

export default function collectionsList({
  addCollection, editCollection, selectCollection, innerRef,
} : props) {
  const selectedProject = useSelector((state: IAppState) => state.projects.UIselectedProject);
  const selectedCollection = useSelector((state: IAppState) => state.projects.UIselectedCollection);
  const viewCollections = useSelector((state: IAppState) => state.projects.UIviewCollections);
  const [addNewCollectionName, setAddNewCollectionName] = useState('');

  useEffect(() => {
    innerRef?.current?.scrollIntoView({ behavior: 'smooth', inline: 'end' });
  }, [viewCollections]);

  const handleRowSelect = (clickedCollection : IProjectCollection, e: any) => {
    e.stopPropagation();
    selectCollection(clickedCollection);
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { value } = e.target;

    setAddNewCollectionName(value);
  };

  const handleScrollToSelf = (e: any) => {
    e.stopPropagation();
    innerRef.current.scrollIntoView({ behavior: 'smooth', inline: 'end' });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    addCollection(addNewCollectionName);
    setAddNewCollectionName('');
  };

  const handleCollectionNameChange = (event: any, value: string, collection: IProjectCollection) => {
    const editCollectionItem : IProjectCollection = {
      ProjectId: collection.ProjectId,
      CollectionId: collection.CollectionId,
      CollectionName: value,
      Description: collection.Description,
      FileDataForCollection: collection.FileDataForCollection,
    };
    if (editCollection) editCollection(editCollectionItem);
  };

  return (
    <>{
      viewCollections
      && (
      <div className="collection-list column" ref={innerRef}>
        <div className="list" onClick={(event) => handleScrollToSelf(event)}>
          {selectedProject
          && selectedProject.Collection.map((item) => (
            <div
              key={item.CollectionId}
              className={`list-item ${item.CollectionId === selectedCollection?.CollectionId ? 'active' : ''}`}
              onClick={(event) => handleRowSelect(item, event)}
            >
              <DoubleClickInput inputType="text" inputValue={item.CollectionName} onInputChange={(e, value) => handleCollectionNameChange(e, value, item)} />
              <FaAngleRight className="angle-right" />
            </div>
          ))}
        </div>
        <div className="add-collection add-item">
          <form className="single-input" onSubmit={handleSubmit}>
            <input type="text" placeholder="add new collection" value={addNewCollectionName} onChange={(e) => handleInputChange(e)} />
            <button type="submit" className="plus" aria-label="add collection">
              <FaPlus size={20} />
            </button>
          </form>
        </div>
      </div>
      )
    }
    </>
  );
}

collectionsList.defaultProps = {
  editCollection: undefined,
};
