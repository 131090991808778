/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from 'react';
import actions from 'actions';
import { useDispatch } from 'react-redux';
import ProjectsService from 'services/projects';
import {
  IProjectCollectionDocument, IProjectCollection, IProjectItem, IProjects, IProjectSavedQuery, ISearchBody, IProjectsCollectionDocumentComment,
} from 'shared/interfaces';
import JsonValidation from 'shared/helpers/JsonValidation';

export default function useProjects(loadProjects: boolean) {
  const dispatch = useDispatch();
  const [loadedProjects, setLoadedProjects] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let isActive = true;

  const buildCollectionFilesItemComments = (items: any) => {
    const collectionFilesItemComments: IProjectsCollectionDocumentComment[] = [];
    if (Array.isArray(items)) {
      for (const item of items) {
        const collectionFileItemComment: IProjectsCollectionDocumentComment = {
          CommentId: item.Id,
          CommentByUserName: item.CreatedBy,
          CommentByDisplayName: item.CreatedByName,
          CommentCreateDateTime: new Date(item.CreatedOn),
          CommentModifiedDateTime: new Date(item.ModifiedOn),
          CommentText: item.Text,
          CommentUserId: item.UserId,
        };
        collectionFilesItemComments.push(collectionFileItemComment);
      }
    }
    return collectionFilesItemComments;
  };

  const buildCollectionFilesItems = (items: any) => {
    const collectionFilesItems: IProjectCollectionDocument[] = [];
    if (Array.isArray(items)) {
      for (const item of items) {
        const collectionFilesItemComments: IProjectsCollectionDocumentComment[] = buildCollectionFilesItemComments(item.comments);
        const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        const path = base64regex.test(item.metadataStoragePath) ? atob(item.metadataStoragePath) : item.metadataStoragePath;
        const collectionFileItem: IProjectCollectionDocument = {
          name: item.name,
          id: item.id,
          path,
          storagePath: item.metadataStoragePath,
          summary: item.summary,
          isDeleted: item.deleted,
          comments: collectionFilesItemComments,
        };
        collectionFilesItems.push(collectionFileItem);
      }
    }
    return collectionFilesItems;
  };

  const buildCollectionItems = (items: any, projectId: string) => {
    const collectionItems: IProjectCollection[] = [];
    if (Array.isArray(items)) {
      for (const item of items) {
        const collectionFilesItems: IProjectCollectionDocument[] = buildCollectionFilesItems(item.savedDocumentFiles);
        const collectionItem: IProjectCollection = {
          ProjectId: projectId,
          CollectionName: item.name,
          CollectionId: item.id,
          Description: item.description,
          FileDataForCollection: collectionFilesItems,
        };
        collectionItems.push(collectionItem);
      }
    }
    return collectionItems;
  };

  const buildSearchBody = (item: any) => ({
    query: item.query,
    searchFields: item.searchFields,
    facets: item.facets,
    filter: item.filter,
    startIndex: item.startIndex,
    resultsPerPage: item.resultsPerPage,
  });

  const buildSavedQueries = (items: any) => {
    const savedQueries: IProjectSavedQuery[] = [];
    if (Array.isArray(items)) {
      for (const item of items) {
        if (JsonValidation.isValidJSON(item.Query)) {
          const savedQueryBody : ISearchBody = buildSearchBody(JSON.parse(item.Query));
          const savedQuery: IProjectSavedQuery = {
            SavedQueryName: item.Name,
            SavedQueryId: item.Id,
            Comment: item.Description,
            Query: savedQueryBody,
          };
          savedQueries.push(savedQuery);
        }
      }
    }
    return savedQueries;
  };

  const buildProjectItem = (item: any) => {
    const collection: IProjectCollection[] = buildCollectionItems(item.collections, item.id);
    const savedQueries: IProjectSavedQuery[] = buildSavedQueries(item.savedQueries);

    const projectItem: IProjectItem = {
      ProjectID: item.id,
      ProjectName: item.name,
      Description: item.description,
      NoOfCollection: item.collectionsCount,
      Collection: collection,
      NoOfSavedQueries: item.NoOfSavedQueries,
      SavedQueries: savedQueries,
      Comment: item.Comment,
      Shared: item.shared,
    };

    return projectItem;
  };

  const buildProjectsItems = async (items: any) => {
    const resultItems: IProjectItem[] = [];
    if (items) {
      for (const item of items) {
        const result = buildProjectItem(item);
        // @ts-ignore
        resultItems.push(result);
      }
    }
    return resultItems;
  };

  const buildProjectsResult = async (data: any) => {
    const projectItems: IProjectItem[] = await buildProjectsItems(data.data);
    const projectsResult: IProjects = {
      Username: data.Username,
      active: true,
      NoOfProjects: data.NoOfProjects,
      Project: projectItems,
      isLoading: false,
    };
    return projectsResult;
  };
  const updateService = () => {
    setIsLoading(true);
    ProjectsService(['Projects.Basic']).then(async (data: any) => {
      if (isActive) {
        if (data.error === true) {
          const projects: IProjects = {
            active: true,
            Username: '',
            NoOfProjects: 0,
            Project: [],
            isLoading: false,
            error: true,
            errorMessage: data.errorMessage,
          };
          dispatch(actions.setProjects(projects));
        } else {
          const projects: IProjects = await buildProjectsResult(data);
          dispatch(actions.setProjects(projects));
        }
        setLoadedProjects(true);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (loadProjects) {
      updateService();
    } else {
      setLoadedProjects(false);
    }

    return () => {
      isActive = false;
    };
  }, [loadProjects]);

  return { loadedProjects, isLoading };
}
