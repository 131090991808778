export default class JsonValidation {
  static isValidJSON(text: string): boolean {
    const sanitized = text.replace(/\\["\\/bfnrtu]/g, '@')
    // Replace JSON primitives (strings, numbers, booleans, null) with ']'
      .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
    // Eliminate erroneous leading colons or commas inside arrays
      .replace(/(?:^|:|,)(?:\s*\[)+/g, '');

    // Check if the sanitized string matches the JSON structure pattern
    if (/^[\],:{}\s]*$/.test(sanitized)) {
      return true; // JSON is OK
    }
    return false; // JSON is not OK
  }
}
