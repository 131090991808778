import { useState, useCallback } from 'react';
import { TCServiceGet } from 'services/t-and-c';

const useTermsAndConditions = () => {
  const [termsSigned, setTermsSigned] = useState(false);
  const [termsMessage, setTermsMessage] = useState('');
  const [termsID, setTermsID] = useState('');
  const [isTermsLoading, setIsTermsLoading] = useState(true);
  const [loadTermsHasError, setLoadTermsHasError] = useState(false);
  const [termsError, setTermsError] = useState<null|string|any>(null);

  const fetchTermsAndConditions = useCallback(async () => {
    setIsTermsLoading(true);
    setTermsError(null);
    setLoadTermsHasError(false);
    try {
      const termsData = await TCServiceGet();
      if (termsData === null) {
        setTermsSigned(true);
      }
      if (termsData) {
        setTermsSigned(termsData.signed);
        setTermsMessage(termsData.text);
        setTermsID(termsData.tacID);
      }
    } catch (error) {
      setTermsError(error);
      setLoadTermsHasError(true);
    } finally {
      setIsTermsLoading(false);
    }
  }, []);

  return {
    termsSigned,
    termsMessage,
    termsID,
    fetchTermsAndConditions,
    isTermsLoading,
    termsError,
    loadTermsHasError,
  };
};

export default useTermsAndConditions;
