import { Configuration, PopupRequest } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const graphLoginRequest: PopupRequest = {
  scopes: ['User.Read'],
};

export const apiLoginRequest: PopupRequest = {
  scopes: [`api://${process.env.REACT_APP_MSAL_CLIENT_ID}/user_impersonation`],
};

// Add here the endpoints for services you would like to use.
export const servicesConfig: { [key: string]: string } = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphAvatarEndpoint: 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
  kmFuncAPIEndpoint: `${process.env.REACT_APP_KMFUNCAPIENDPOINT}`,
};

// add more servicesConfig
export const addServiceConfig = (service: string, url: string) => {
  servicesConfig[service] = url;
};
