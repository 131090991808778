import { IFacetsConfiguration } from 'shared/interfaces';

export const initialState: IFacetsConfiguration = {
  facets: [],
};

const facetsConfiguration = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_FACETS_CONFIGURATION':
      return action.payload ? action.payload : initialState;
    default:
      return state;
  }
};

export default facetsConfiguration;
