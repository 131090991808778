import React from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import userHasRoles from 'shared/helpers/UserRoles';

/**
 * Props for the RoleGuardedComponent.
 */
interface RoleGuardedComponentProps {
  /**
   * An array of role names that are required for the user to access the content.
   */
  requiredRoles: string[];

  /**
   * A fallback message to display when the user does not have the required roles.
   * If not provided, the component will render nothing when the user lacks the required roles.
   */
  fallbackMessage?: string;

  /**
   * Additional inline styles to apply to the fallback message container.
   */
  fallbackMessageStyle?: React.CSSProperties;

  /**
   * The child components to be rendered when the user has the required roles.
   */
  children: React.ReactNode;
}

/**
 * A component that guards the rendering of its children based on the user's roles.
 * If the user has the required roles, it renders the children; otherwise,
 * it either shows a fallback message if provided or doesn't render anything if not provided.
 */
const RoleGuardedComponent = ({
  requiredRoles,
  fallbackMessage,
  fallbackMessageStyle,
  children,
}: RoleGuardedComponentProps) => {
  const messageStyle: React.CSSProperties = {
    textAlign: 'center',
    display: 'block',
    ...fallbackMessageStyle,
  };

  if (userHasRoles(requiredRoles)) {
    return <>{children}</>;
  } if (fallbackMessage) {
    return (
      <Box style={messageStyle}>
        <Alert severity="warning">{fallbackMessage}</Alert>
      </Box>
    );
  }
  return null;
};

RoleGuardedComponent.defaultProps = {
  fallbackMessage: undefined,
  fallbackMessageStyle: {},
};

export default RoleGuardedComponent;
