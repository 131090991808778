import { ISearchBody } from 'shared/interfaces';

export const initialState: ISearchBody = {
  query: '',
  facets: {},
  searchFields: '',
  filter: '',
  startIndex: 0,
  resultsPerPage: 10,
};

const searchBody = (state: ISearchBody = initialState, action: any) => {
  switch (action.type) {
    case 'SET_SEARCH_BODY_QUERY':
      return action.payload;
    default:
      return state;
  }
};

export default searchBody;
