import { servicesConfig } from 'authConfig';
import baseFetch, { FetchResponse } from 'services/baseFetch';
import { UserData } from 'shared/interfaces';

export async function getUserAllowance() {
  const searchServiceURL = `${servicesConfig.kmFuncAPIEndpoint}/api/user/allowance`;
  const defaultMessage = 'Error fetching user information.';
  try {
    const result = await baseFetch(searchServiceURL, {
      method: 'GET',
    });
    const userDataFromResponse: UserData = await result.data;
    return userDataFromResponse;
  } catch (error) {
    let errorMessage = defaultMessage;
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    const errorResponse: FetchResponse = {
      error: true,
      errorMessage,
      statusCode: null,
      statusMessage: null,
    };

    return errorResponse;
  }
}

export default getUserAllowance;
