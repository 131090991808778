import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import actions from 'actions';
import Logo from 'assets/Logo.png';
import ECLogo from 'assets/black-and-blue-logo.png';
import { TabContext } from 'shared/contexts/tabs-context';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FaUser } from 'react-icons/fa';
import { UserData } from 'shared/interfaces';
import getUserAllowance from 'services/getUserInfo';
import './index.css';

const useStyles = makeStyles(() => ({
  indicator: {
    backgroundColor: 'var(--base-blue)',
  },
  tab: {
    '&$selected': {
      color: 'var(--text-dark)',
      fontWeight: 'bold',
    },
  },
  selected: {},
}));

export default function Header() {
  const context = useContext(TabContext);
  const [showUser, setShowUser] = useState(false);
  const [showTokenWarning, setShowTokenWarning] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const envShowUserInfo = process.env.REACT_APP_ENABLE_USER_INFO !== 'false';
  const [showUserInfo, setShowUserInfo] = useState(false);
  const showTokenWarningFlag = process.env.REACT_APP_DISABLE_TOKEN_WARNING;
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const isMounted = useRef(true);

  if (!context) {
    return <div>Error: Context not found</div>;
  }

  const { selectedTab, setSelectedTab, availableModes } = context;

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(event, newValue);
    if (location.pathname !== '/') {
      history.push('/');
    }
  };

  const dispatch = useDispatch();
  const navHistory = useHistory();

  const goToHomeScreen = (e: any) => {
    e.preventDefault();

    dispatch(actions.setTextToSearch({ text: '' }));
    dispatch(actions.setFieldsToSearch({ text: '' }));
    dispatch(
      actions.setSearchResult({
        results: [],
        facets: [],
        total: 0,
        isLoading: true,
      }),
    );

    navHistory.push({ pathname: '/' });
  };

  const fetchAllowance = useCallback(async () => {
    if (!showUserInfo) return;
    const allowance = await getUserAllowance();
    if (allowance && isMounted.current) {
      if (!('error' in allowance) || !allowance.error) {
        if ('allowance' in allowance && 'initialAllowance' in allowance) {
          setUserData(allowance as UserData);
        }
      }
    }
  }, [showUserInfo]);

  const showUserInfoDiv = () => {
    setShowUser((prevShowUser) => {
      if (!prevShowUser) {
        fetchAllowance();
      }
      return !prevShowUser;
    });
  };

  useEffect(() => {
    isMounted.current = true;
    fetchAllowance();
    return () => {
      isMounted.current = false;
    };
  }, [fetchAllowance]);

  useEffect(() => {
    if (userData != null) {
      setShowTokenWarning(userData.allowance < userData.initialAllowance / 2);
    }
  }, [userData]);

  useEffect(() => {
    setShowUserInfo(envShowUserInfo);
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // function to read css variable value
  const getCSSVar = (variable: string) => getComputedStyle(document.body).getPropertyValue(variable);

  // input svg file path, read file and replace color function and return base64 image
  const replaceColor = (svgPath: string, color: string) => {
    const svgFile = new XMLHttpRequest();
    svgFile.open('GET', svgPath, false);
    svgFile.send();
    const svgDoc = svgFile.responseXML;
    const svg = svgDoc?.getElementsByTagName('svg')[0];
    if (svg) {
      const styleTag = svg.querySelector('defs style');
      if (styleTag) {
        styleTag.innerHTML = styleTag.innerHTML.replace(/(\.cls-1\s*\{[^}]*fill\s*:\s*)#[a-fA-F0-9]{6}/, `$1${color}`);
      }

      return `data:image/svg+xml;base64,${btoa(svg.outerHTML)}`;
    }
    return svgPath;
  };

  return (
    <div className="header-container">
      <div className="header-left">
        { Logo.split('.').pop() === 'svg'
          ? <img alt="KM Logo" src={replaceColor(Logo, getCSSVar('--base-blue'))} className="logo" onClick={goToHomeScreen} />
          : <img alt="KM Logo" src={Logo} className="logo" onClick={goToHomeScreen} />}
      </div>
      <div className="tabs-container">
        <Tabs
          value={selectedTab.id}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          classes={{ indicator: classes.indicator }}
        >
          {availableModes.map((modeValue) => (
            <Tab
              label={
                modeValue.label
                || `${modeValue.type.charAt(0).toUpperCase() + modeValue.type.slice(1)}${modeValue.version ? ` v${modeValue.version}` : ''} Mode`
              }
              value={modeValue.id}
              key={modeValue.id}
              className={`${classes.tab} ${modeValue.id === selectedTab.id ? classes.selected : ''}`}
            />
          ))}
        </Tabs>
      </div>
      <div className="header-right">
        <div className="user-info-container">
          {(showUserInfo || showUserInfo === undefined) && userData && (
            <div className={showTokenWarning && (showTokenWarningFlag === 'false' || showTokenWarningFlag === undefined) ? 'low-token-div' : 'token-div'}>
              {showTokenWarning && (showTokenWarningFlag === 'false' || showTokenWarningFlag === undefined) && (
                <div className="low-token-warning-div">Your token credit is below 50%</div>
              )}
              <button
                className="user-info-button"
                type="button"
                aria-label="user info"
                onClick={showUserInfoDiv}
              >
                <FaUser />
              </button>
            </div>
          )}
          {showUser && (
            <div className="user-info-div">
              <p>
                <span className="user-title">{userData!.identity}</span>
              </p>
              <p>
                <span className="highlight">Allowance:</span> {userData!.allowance}
              </p>
              <p className="initial-allowance">
                <span className="highlight">Initial Allowance:</span> {userData!.initialAllowance}
              </p>
            </div>
          )}
        </div>
        <img alt="ElastaCloud Logo" src={ECLogo} className="EC_logo" onClick={goToHomeScreen} />
      </div>
    </div>
  );
}
