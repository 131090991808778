import {
  IProjects, IProjectCollectionDocument,
} from 'shared/interfaces';

export const initialState: IProjects = {
  Username: '',
  NoOfProjects: 0,
  Project: [],
  isLoading: false,
  active: false,
};

const projectsResult = (state = initialState, action: any) => {
  switch (action.type) {
    case 'PROJECTS_SET_PROJECTS':
      return {
        ...initialState,
        ...(action.payload || {}),
        UIopenProjectsSelector: action.payload.UIopenProjectsSelector !== undefined ? action.payload.UIopenProjectsSelector : state.UIopenProjectsSelector,
        UIprojectsSelectorType: action.payload.UIprojectsSelectorType !== undefined ? action.payload.UIprojectsSelectorType : state.UIprojectsSelectorType,
        UIprojectsSelectorDocument:
          action.payload.UIprojectsSelectorDocument !== undefined ? action.payload.UIprojectsSelectorDocument : state.UIprojectsSelectorDocument,
      };
    case 'PROJECTS_ADD_PROJECT':
      return action.payload ? { ...state, Project: [...state.Project, action.payload] } : state;
    case 'PROJECTS_EDIT_PROJECT':
      return action.payload ? {
        ...state,
        Project: state.Project.map((project) => {
          if (project.ProjectID === action.payload.id) {
            const editedProject = project;
            editedProject.ProjectName = action.payload.name;
            editedProject.Description = action.payload.description;
            return editedProject;
          }
          return project;
        }),
        UIselectedProject: {
          ...state.UIselectedProject,
          ProjectName: action.payload.name,
          Description: action.payload.description,
        },
      } : state;
    case 'PROJECTS_DELETE_PROJECT':
      return state.UIselectedProject ? {
        ...state,
        Project: state.Project.filter((project) => project.ProjectID !== state.UIselectedProject?.ProjectID),
        UIselectedProject: undefined,
      } : state;
    case 'PROJECTS_ADD_COLLECTION':
      return action.payload && state.UIselectedProject ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            Collection: [...project.Collection, action.payload],
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          Collection: [...state.UIselectedProject.Collection, action.payload],
        },
      } : state;
    case 'PROJECTS_EDIT_COLLECTION':
      return action.payload && state.UIselectedProject ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            Collection: project.Collection.map((collection) => {
              if (collection.CollectionId === action.payload.id) {
                const editedCollection = collection;
                editedCollection.CollectionName = action.payload.name;
                editedCollection.Description = action.payload.description;
                return editedCollection;
              }
              return collection;
            }),
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          Collection:
          state.UIselectedProject.Collection.map((collection) => {
            if (collection.CollectionId === action.payload.id) {
              const editedCollection = collection;
              editedCollection.CollectionName = action.payload.name;
              editedCollection.Description = action.payload.description;
              return editedCollection;
            }
            return collection;
          }),
        },
      } : state;
    case 'PROJECTS_DELETE_COLLECTION':
      return action.payload && state.UIselectedProject && state.UIselectedCollection ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            Collection: project.Collection.filter((collection) => collection.CollectionId !== action.payload.CollectionId),
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          Collection: state.UIselectedProject.Collection.filter((collection) => collection.CollectionId !== action.payload.CollectionId),
        },
        UIselectedCollection: undefined,
      } : state;
    case 'PROJECTS_ADD_QUERY':
      return action.payload && state.UIselectedProject ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            SavedQueries: [...project.SavedQueries, action.payload],
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          SavedQueries: [...state.UIselectedProject.SavedQueries, action.payload],
        },
      } : state;
    case 'PROJECTS_EDIT_QUERY':
      return action.payload && state.UIselectedProject ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            SavedQueries: project.SavedQueries.map((savedQuery) => {
              if (savedQuery.SavedQueryId === action.payload.id) {
                const editedSavedQuery = savedQuery;
                editedSavedQuery.SavedQueryName = action.payload.name;
                editedSavedQuery.Comment = action.payload.description;
                return editedSavedQuery;
              }
              return savedQuery;
            }),
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          SavedQueries: state.UIselectedProject.SavedQueries.map((savedQuery) => {
            if (savedQuery.SavedQueryId === action.payload.id) {
              const editedSavedQuery = savedQuery;
              editedSavedQuery.SavedQueryName = action.payload.name;
              editedSavedQuery.Comment = action.payload.description;
              return editedSavedQuery;
            }
            return savedQuery;
          }),
        },
      } : state;
    case 'PROJECTS_DELETE_QUERY':
      return action.payload && state.UIselectedProject ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            SavedQueries: project.SavedQueries.filter((savedQuery) => savedQuery.SavedQueryId !== action.payload.SavedQueryId),
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          SavedQueries: state.UIselectedProject.SavedQueries.filter((savedQuery) => savedQuery.SavedQueryId !== action.payload.SavedQueryId),
        },
        UIselectedQuery: undefined,
      } : state;
    case 'PROJECTS_ADD_DOCUMENT':
      return action.payload && state.UIselectedProject && state.UIselectedCollection ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            Collection: project.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
              ? {
                ...Collection,
                FileDataForCollection: [...Collection.FileDataForCollection, action.payload],
              } : Collection)),
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          Collection: state.UIselectedProject.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
            ? {
              ...Collection,
              FileDataForCollection: [...Collection.FileDataForCollection, action.payload],
            } : Collection)),
        },
        UIselectedCollection: {
          ...state.UIselectedCollection,
          FileDataForCollection: [...state.UIselectedCollection.FileDataForCollection, action.payload],
        },
      } : state;
    case 'PROJECTS_REMOVE_DOCUMENTS':
      return action.payload && state.UIselectedProject && state.UIselectedCollection && state.UIselectedDocument ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            Collection: project.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
              ? {
                ...Collection,
                FileDataForCollection:
                Collection.FileDataForCollection.filter(
                  (obj) => !action.payload.some(
                    (obj2: IProjectCollectionDocument) => obj.storagePath === obj2.storagePath,
                  ),
                ),
              } : Collection)),
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          Collection: state.UIselectedProject.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
            ? {
              ...Collection,
              FileDataForCollection: Collection.FileDataForCollection.filter(
                (obj) => !action.payload.some(
                  (obj2: IProjectCollectionDocument) => obj.storagePath === obj2.storagePath,
                ),
              ),
            } : Collection)),
        },
        UIselectedCollection: {
          ...state.UIselectedCollection,
          FileDataForCollection: state.UIselectedCollection.FileDataForCollection.filter(
            (obj) => !action.payload.some(
              (obj2: IProjectCollectionDocument) => obj.storagePath === obj2.storagePath,
            ),
          ),
        },
        UIselectedDocument: undefined,
      } : state;
    case 'PROJECTS_REMOVE_DOCUMENT':
      return action.payload && state.UIselectedProject && state.UIselectedCollection && state.UIselectedDocument ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            Collection: project.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
              ? {
                ...Collection,
                FileDataForCollection: Collection.FileDataForCollection.filter((file) => file.storagePath !== action.payload.storagePath),
              } : Collection)),
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          Collection: state.UIselectedProject.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
            ? {
              ...Collection,
              FileDataForCollection: Collection.FileDataForCollection.filter((file) => file.storagePath !== action.payload.storagePath),
            } : Collection)),
        },
        UIselectedCollection: {
          ...state.UIselectedCollection,
          FileDataForCollection: state.UIselectedCollection.FileDataForCollection.filter((file) => file.storagePath !== action.payload.storagePath),
        },
        UIselectedDocument: undefined,
      } : state;
    case 'PROJECTS_REMOVE_DOCUMENT_COMMENT':
      return action.payload && state.UIselectedProject && state.UIselectedCollection && state.UIselectedDocument ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            Collection: project.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
              ? {
                ...Collection,
                FileDataForCollection: Collection.FileDataForCollection.map((file) => (file.id === state.UIselectedDocument?.id
                  ? {
                    ...file,
                    comments: file.comments?.filter((comment) => comment.CommentId !== action.payload.CommentId),
                  } : file)),
              } : Collection)),
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          Collection: state.UIselectedProject.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
            ? {
              ...Collection,
              FileDataForCollection: Collection.FileDataForCollection.map((file) => (file.id === state.UIselectedDocument?.id
                ? {
                  ...file,
                  comments: file.comments?.filter((comment) => comment.CommentId !== action.payload.CommentId),
                } : file)),
            } : Collection)),
        },
        UIselectedCollection: {
          ...state.UIselectedCollection,
          FileDataForCollection: state.UIselectedCollection.FileDataForCollection.map((file) => (file.id === state.UIselectedDocument?.id
            ? {
              ...file,
              comments: file.comments?.filter((comment) => comment.CommentId !== action.payload.CommentId),
            } : file)),
        },
        UIselectedDocument: {
          ...state.UIselectedDocument,
          comments: state.UIselectedDocument.comments?.filter((comment) => comment.CommentId !== action.payload.CommentId),
        },
      } : state;
    case 'PROJECTS_EDIT_DOCUMENT_COMMENT':
      return action.payload && state.UIselectedProject && state.UIselectedCollection && state.UIselectedDocument ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            Collection: project.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
              ? {
                ...Collection,
                FileDataForCollection: Collection.FileDataForCollection.map((file) => (file.id === state.UIselectedDocument?.id
                  ? {
                    ...file,
                    comments: file.comments?.map((comment) => {
                      if (comment.CommentId === action.payload.CommentId) {
                        const editedComment = comment;
                        editedComment.CommentText = action.payload.CommentText;
                        editedComment.CommentModifiedDateTime = new Date(action.payload.CommentModifiedDateTime);
                        return editedComment;
                      }
                      return comment;
                    }),
                  } : file)),
              } : Collection)),
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          Collection: state.UIselectedProject.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
            ? {
              ...Collection,
              FileDataForCollection: Collection.FileDataForCollection.map((file) => (file.id === state.UIselectedDocument?.id
                ? {
                  ...file,
                  comments: file.comments?.map((comment) => {
                    if (comment.CommentId === action.payload.CommentId) {
                      const editedComment = comment;
                      editedComment.CommentText = action.payload.CommentText;
                      editedComment.CommentModifiedDateTime = new Date(action.payload.CommentModifiedDateTime);
                      return editedComment;
                    }
                    return comment;
                  }),
                } : file)),
            } : Collection)),
        },
        UIselectedCollection: {
          ...state.UIselectedCollection,
          FileDataForCollection: state.UIselectedCollection.FileDataForCollection.map((file) => (file.id === state.UIselectedDocument?.id
            ? {
              ...file,
              comments: file.comments?.map((comment) => {
                if (comment.CommentId === action.payload.CommentId) {
                  const editedComment = comment;
                  editedComment.CommentText = action.payload.CommentText;
                  editedComment.CommentModifiedDateTime = new Date(action.payload.CommentModifiedDateTime);
                  return editedComment;
                }
                return comment;
              }),
            } : file)),
        },
        UIselectedDocument: {
          ...state.UIselectedDocument,
          comments: state.UIselectedDocument.comments?.map((comment) => {
            if (comment.CommentId === action.payload.CommentId) {
              const editedComment = comment;
              editedComment.CommentText = action.payload.CommentText;
              editedComment.CommentModifiedDateTime = new Date(action.payload.CommentModifiedDateTime);
              return editedComment;
            }
            return comment;
          }),
        },
      } : state;
    case 'PROJECTS_ADD_DOCUMENT_COMMENT':
      return action.payload && state.UIselectedProject && state.UIselectedCollection && state.UIselectedDocument ? {
        ...state,
        Project: state.Project.map((project) => (project.ProjectID === state.UIselectedProject?.ProjectID
          ? {
            ...project,
            Collection: project.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
              ? {
                ...Collection,
                FileDataForCollection: Collection.FileDataForCollection.map((file) => (file.id === state.UIselectedDocument?.id
                  ? {
                    ...file,
                    comments: [...file.comments, action.payload],
                  } : file)),
              } : Collection)),
          } : project)),
        UIselectedProject: {
          ...state.UIselectedProject,
          Collection: state.UIselectedProject.Collection.map((Collection) => (Collection.CollectionId === state.UIselectedCollection?.CollectionId
            ? {
              ...Collection,
              FileDataForCollection: Collection.FileDataForCollection.map((file) => (file.id === state.UIselectedDocument?.id
                ? {
                  ...file,
                  comments: [...file.comments, action.payload],
                } : file)),
            } : Collection)),
        },
        UIselectedCollection: {
          ...state.UIselectedCollection,
          FileDataForCollection: state.UIselectedCollection.FileDataForCollection.map((file) => (file.id === state.UIselectedDocument?.id
            ? {
              ...file,
              comments: [...file.comments, action.payload],
            } : file)),
        },
        UIselectedDocument: {
          ...state.UIselectedDocument,
          comments: [...state.UIselectedDocument.comments, action.payload],
        },
      } : state;
    case 'PROJECTS_SELECT_PROJECT':
      return action.payload ? {
        ...state,
        UIselectedProject: action.payload,
        UIviewQueries: false,
        UIviewCollections: false,
        UIselectedCollection: undefined,
        UIselectedQuery: undefined,
      } : state;
    case 'PROJECTS_VIEW_COLLECTIONS':
      // TODO: combine uiviewCollections + UIviewQueries in single property
      return action.payload ? {
        ...state,
        UIviewCollections: action.payload,
        UIviewQueries: false,
        UIselectedCollection: undefined,
        UIselectedQuery: undefined,
        UIselectedDocument: undefined,
      } : state;
    case 'PROJECTS_VIEW_QUERIES':
      return action.payload ? {
        ...state,
        UIviewQueries: action.payload,
        UIviewCollections: false,
        UIselectedCollection: undefined,
        UIselectedQuery: undefined,
        UIselectedDocument: undefined,
      } : state;
    case 'PROJECTS_SELECT_COLLECTION':
      return action.payload ? {
        ...state,
        UIselectedCollection: action.payload,
        UIselectedQuery: undefined,
        UIselectedDocument: undefined,
      } : state;
    case 'PROJECTS_SELECT_DOCUMENT':
      return action.payload ? {
        ...state, UIselectedDocument: action.payload,
      } : state;
    case 'PROJECTS_SELECT_QUERY':
      return action.payload ? {
        ...state, UIselectedQuery: action.payload,
      } : state;
    case 'PROJECTS_TOGGLE_SELECTOR':
      return action.payload !== undefined ? {
        ...state,
        UIopenProjectsSelector: action.payload !== undefined ? action.payload : state.UIopenProjectsSelector,
      } : state;
    case 'PROJECTS_SET_SELECTOR_TYPE':
      return action.payload ? {
        ...state,
        UIprojectsSelectorType: action.payload !== undefined ? action.payload : state.UIprojectsSelectorType,
      } : state;
    case 'PROJECTS_SET_SELECTOR_DOCUMENT':
      return action.payload ? {
        ...state, UIprojectsSelectorDocument: action.payload,
      } : state;
    case 'PROJECTS_DOCUMENT_DELETED':
      return action.payload && state.UIselectedProject && state.UIselectedCollection ? {
        ...state,
        Project: state.Project.map((project) => ({
          ...project,
          Collection: project.Collection.map((Collection) => ({
            ...Collection,
            FileDataForCollection: Collection.FileDataForCollection.map((document) => {
              if (document.storagePath === action.payload) {
                const editedDocument = document;
                editedDocument.isDeleted = true;
                return editedDocument;
              }
              return document;
            }),
          })),
        })),
        UIselectedProject: {
          ...state.UIselectedProject,
          Collection: state.UIselectedProject.Collection.map((Collection) => ({
            ...Collection,
            FileDataForCollection: Collection.FileDataForCollection.map((document) => {
              if (document.storagePath === action.payload) {
                const editedDocument = document;
                editedDocument.isDeleted = true;
                return editedDocument;
              }
              return document;
            }),
          })),
        },
        UIselectedCollection: {
          ...state.UIselectedCollection,
          FileDataForCollection: state.UIselectedCollection.FileDataForCollection.map((document) => {
            if (document.storagePath === action.payload) {
              const editedDocument = document;
              editedDocument.isDeleted = true;
              return editedDocument;
            }
            return document;
          }),
        },
      } : state;
    default:
      return state;
  }
};

export default projectsResult;
