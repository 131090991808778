import React, {
  createContext, useContext, useState, useEffect, ReactNode,
} from 'react';

type SnipedFacetsContextType = {
  snipedFacets: string[];
  setSnipedFacets: (facets: string[]) => void;
};

const SnipedFacetsContext = createContext<SnipedFacetsContextType | undefined>(undefined);

export const SnipedFacetsProvider = ({ children }: { children: ReactNode }) => {
  const [snipedFacets, setSnipedFacets] = useState<string[]>(() => {
    if (typeof window === 'undefined') {
      return [];
    }
    try {
      const item = window.sessionStorage.getItem('snipedFacets');
      return item ? JSON.parse(item) : [];
    } catch (error) {
      return [];
    }
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('snipedFacets', JSON.stringify(snipedFacets));
    }
  }, [snipedFacets]);

  return (
    <SnipedFacetsContext.Provider value={{ snipedFacets, setSnipedFacets }}>
      {children}
    </SnipedFacetsContext.Provider>
  );
};

export const useSnipedFacets = () => {
  const context = useContext(SnipedFacetsContext);
  if (context === undefined) {
    throw new Error('useSnipedFacets must be used within a SnipedFacetsProvider');
  }
  return context;
};
