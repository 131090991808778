/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
import { servicesConfig } from 'authConfig';
import baseFetch, { FetchResponse } from 'services/baseFetch';
import UserDataOperations from 'shared/enums/UserdataOperations';
import UserDataScopes from 'shared/enums/UserdataScopes';
import { IURLParam } from 'shared/interfaces';

export default async function ProjectsService(roles: string[]): Promise<FetchResponse> {
  const url = `${servicesConfig.kmFuncAPIEndpoint}/api/userdata/${UserDataOperations.Read}/${UserDataScopes.Projects}/`;
  const defaultMessage = 'Error loading projects';

  try {
    const result = await baseFetch(url, {
      method: 'POST',
      defaultMessage,
      requiredRoles: roles,
    });
    return result;
  } catch (error) {
    let errorMessage = defaultMessage;
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    const errorResponse: FetchResponse = {
      error: true,
      errorMessage,
      statusCode: null,
      statusMessage: null,
    };

    return errorResponse;
  }
}

export async function CreateUserData(payload: string, operation: UserDataOperations, scope: UserDataScopes): Promise<FetchResponse> {
  const url = `${servicesConfig.kmFuncAPIEndpoint}/api/userdata/${operation}/${scope}`;
  const defaultMessage = `Error creating ${scope}`;

  try {
    const result = await baseFetch(url, {
      method: 'POST',
      defaultMessage,
      body: payload,
    });
    return result;
  } catch (error) {
    let errorMessage = defaultMessage;
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    const errorResponse: FetchResponse = {
      error: true,
      errorMessage,
      statusCode: null,
      statusMessage: null,
    };

    return errorResponse;
  }
}

export async function UpdateUserData(
  payload: string,
  operation: UserDataOperations,
  scope: UserDataScopes,
): Promise<FetchResponse> {
  const url = `${servicesConfig.kmFuncAPIEndpoint}/api/userdata/${operation}/${scope}`;
  const defaultMessage = `Error updating ${scope}`;

  try {
    const result = await baseFetch(url, {
      method: 'POST',
      defaultMessage,
      body: payload,
    });
    return result;
  } catch (error) {
    let errorMessage = defaultMessage;
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    const errorResponse: FetchResponse = {
      error: true,
      errorMessage,
      statusCode: null,
      statusMessage: null,
    };

    return errorResponse;
  }
}

export async function DeleteUserData(
  payload: string | undefined = undefined,
  operation: UserDataOperations,
  scope: UserDataScopes,
  queryParams: IURLParam[],
): Promise<FetchResponse> {
  const url = `${servicesConfig.kmFuncAPIEndpoint}/api/userdata/${operation}/${scope}`;
  const defaultMessage = `Error deleting ${scope}`;

  try {
    const result = await baseFetch(url, {
      method: 'delete',
      defaultMessage,
      body: payload,
      queryParams,
    });
    return result;
  } catch (error) {
    let errorMessage = defaultMessage;
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    const errorResponse: FetchResponse = {
      error: true,
      errorMessage,
      statusCode: null,
      statusMessage: null,
    };

    return errorResponse;
  }
}
