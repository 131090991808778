import { IAskSearchResult } from 'shared/interfaces';

export const initialState: IAskSearchResult = {
  OpenAIAnswer: '',
  OpenAIAnswerType: undefined,
  isLoading: false,
};

const searchAskResult = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_ASK_SEARCH_RESULT':
      return action.payload ? action.payload : initialState;
    default:
      return state;
  }
};

export default searchAskResult;
