import { ISearchResultHighlightPreview } from 'shared/interfaces';

export const initialState: ISearchResultHighlightPreview = {
  isVisible: false,
  currentHighlight: '',
};

const searchHighlightPreview = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_SEARCH_RESULT_HIGHLIGHT':
      return action.payload ? action.payload : initialState;
    default:
      return state;
  }
};

export default searchHighlightPreview;
