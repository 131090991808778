import { IPdfPreview } from 'shared/interfaces';

export const initialState: IPdfPreview = {
  pdfURL: '',
  showPdf: false,
};

const toSearch = (state: IPdfPreview = initialState, action: any) => {
  switch (action.type) {
    case 'SET_PDF_PREVIEW':
      return action.payload;
    default:
      return state;
  }
};

export default toSearch;
