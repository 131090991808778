export default class TextCleaner {
  static removeHtmlTags(text: string) {
    return text?.replace(/<\/?[^>]+(>|$)/g, '');
  }

  static removeNewLineCharacters(text: string) {
    return text?.replace(/\n|\r|\t/g, '');
  }

  static removeHighlightTag(text: string) {
    return text?.replace(/<\/em>/g, '');
  }

  static trim(text: string, character: string) {
    let start = 0;
    let end = text.length;

    while (start < end && text[start] === character) { start += 1; }

    while (end > start && text[end - 1] === character) { end -= 1; }

    return (start > 0 || end < text.length) ? text.substring(start, end) : text;
  }

  static escapeODataFilter(text: string) {
    let escapedFilter = text;
    escapedFilter = escapedFilter.replace(/'/g, "''");

    escapedFilter = escapedFilter.replace(/"+"/g, '%2B');
    escapedFilter = escapedFilter.replace(/\//g, '%2F');
    escapedFilter = escapedFilter.replace(/"?"/g, '%3F');
    escapedFilter = escapedFilter.replace(/%/g, '%25');
    escapedFilter = escapedFilter.replace(/#/g, '%23');
    escapedFilter = escapedFilter.replace(/&/g, '%26');
    return escapedFilter;
  }

  static unescapeODataFilter(text: string) {
    let escapedFilter = text;
    escapedFilter = escapedFilter.replace("''", "'");

    escapedFilter = escapedFilter.replace('%2B', '+');
    escapedFilter = escapedFilter.replace('%2F', '/');
    escapedFilter = escapedFilter.replace('%3F', '?');
    escapedFilter = escapedFilter.replace('%25', '%');
    escapedFilter = escapedFilter.replace('%23', '#');
    escapedFilter = escapedFilter.replace('%26', '&');
    return escapedFilter;
  }
}
