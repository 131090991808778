import { ISearchResult } from 'shared/interfaces';

export const initialState: ISearchResult = {
  results: [],
  facets: [],
  total: 0,
  isLoading: true,
};

const searchResult = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_SEARCH_RESULT':
      return action.payload ? action.payload : initialState;
    default:
      return state;
  }
};

export default searchResult;
