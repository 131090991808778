import { IToSearch } from 'shared/interfaces';

export const initialState: IToSearch = {
  text: '',
};

const toSearchAsk = (state: IToSearch = initialState, action: any) => {
  switch (action.type) {
    case 'SET_TEXT_TO_SEARCH_ASK':
      return action.payload;
    default:
      return state;
  }
};

export default toSearchAsk;
