import React, {
  createContext, useContext, useState, ReactNode,
} from 'react';
import { ModeDetails } from 'hooks/useAvailableModes';
import { SearchModeContext } from './search-mode-context';

interface TabContextProps {
  selectedTab: ModeDetails;
  setSelectedTab: (event: React.ChangeEvent<{}>, newValue: string) => void;
  availableModes: ModeDetails[];
}

export const TabContext = createContext<TabContextProps | null>(null);

export const TabProvider = ({ children }: { children: ReactNode }) => {
  const { mode, setMode, availableModes } = useContext(SearchModeContext);

  const setSelectedTab = (_: any, newValue: string) => {
    const newMode = availableModes.find((m) => m.id === newValue);
    if (newMode) {
      setMode(newMode);
    }
  };

  return (
    <TabContext.Provider value={{ selectedTab: mode, setSelectedTab, availableModes }}>
      {children}
    </TabContext.Provider>
  );
};
