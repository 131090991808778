import { useMsal, useAccount } from '@azure/msal-react';

/**
 * Checks if the current user has at least one of the specified roles.
 * @param {string | string[]} roles - A role or list of roles to check against the user's roles.
 * @returns {boolean} True if the user has at least one of the specified roles; otherwise, false.
 */
const userHasRoles = (roles: string | string[]): boolean => {
  const { accounts } = useMsal();
  const currentAccount = useAccount(accounts[0] || {}) as any;

  const rolesArray = Array.isArray(roles) ? roles : [roles];

  if (currentAccount && currentAccount.idTokenClaims && currentAccount.idTokenClaims.roles) {
    const userRoles: string[] = currentAccount.idTokenClaims.roles;
    return rolesArray.some((role) => userRoles.includes(role));
  }
  return false;
};

export default userHasRoles;
