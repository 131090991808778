import { IAskSearchBody } from 'shared/interfaces';

export const initialState: IAskSearchBody = {
  query: '',
  filter: '',
  searchFields: '',
  startIndex: 0,
  resultsPerPage: 10,
};

const searchAskBody = (state: IAskSearchBody = initialState, action: any) => {
  switch (action.type) {
    case 'SET_SEARCH_ASK_BODY_QUERY':
      return action.payload;
    default:
      return state;
  }
};

export default searchAskBody;
