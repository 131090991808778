import { servicesConfig } from 'authConfig';
import baseFetch from 'services/baseFetch';
import { IRemoveBody } from 'shared/interfaces';

export default async function RemoveService(removeBody: IRemoveBody) {
  const removeServiceURL = `${servicesConfig.kmFuncAPIEndpoint}/api/files/delete/${removeBody.uniqueValues[0]}`;

  const resultsFormatted = {
    error: false,
    errorMessage: '',
  };

  const defaultMessage = 'Error removing document';

  try {
    const response = await baseFetch(removeServiceURL, {
      method: 'delete',
    });
    resultsFormatted.error = response.error;
    resultsFormatted.errorMessage = response.errorMessage || defaultMessage;
  } catch (err: unknown) {
    resultsFormatted.error = true;
    if (err instanceof Error) {
      resultsFormatted.errorMessage = err.message;
    }
  }

  return resultsFormatted;
}

export async function RemoveMultipleService(removeBody: IRemoveBody) {
  const removeServiceURL = `${servicesConfig.kmFuncAPIEndpoint}/api/files/delete/${removeBody.uniqueValues}`;
  const resultsFormatted = {
    error: false,
    errorMessage: '',
  };

  const defaultMessage = 'Error removing documents';

  try {
    const response = await baseFetch(removeServiceURL, {
      method: 'delete',
      body: JSON.stringify(removeBody),
    });
    resultsFormatted.error = response.error;
    resultsFormatted.errorMessage = response.errorMessage || defaultMessage;
  } catch (err: unknown) {
    resultsFormatted.error = true;
    if (err instanceof Error) {
      resultsFormatted.errorMessage = err.message;
    }
  }
}
