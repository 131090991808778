import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';
import Logo from 'assets/Logo.png';
import ECLogo from 'assets/black-and-blue-logo.png';
import { TcPost } from 'shared/interfaces';
import { TCServiceGet, TCServicePost } from 'services/t-and-c';
import Markdown from 'markdown-to-jsx';
import './index.css';

interface TCAcceptanceProps {
  /**
   * An array of role names that are required for the user to access the content.
   */
  userAcceptance: boolean;
  /**
   * A fallback message to display when the user does not have accepted the TC.
   * If not provided, the component will render nothing when the user lacks the TC.
   */
  fallbackMessage?: string;
  /**
   * TacID for the post wo we know witch t&c are being accepted.
   */
  tacID: string;
  /**
   * The child components to be rendered when the user has the required roles.
   */
  children: React.ReactNode;
}

/**
 * A component that guards the rendering of its children based on the user's roles.
 * If the user has the required roles, it renders the children; otherwise,
 * it either shows a fallback message if provided or doesn't render anything if not provided.
 */
const TCAcceptance = ({
  userAcceptance,
  fallbackMessage,
  tacID,
  children,
}: TCAcceptanceProps) => {
  const [accepted, setAccepted] = useState(userAcceptance);
  const [isVisible, setIsVisible] = useState(false);
  const enableTerms = process.env.REACT_APP_ENABLE_TERMS;
  /**
   * Checks if the user has at least one of the required roles.
   * @returns {boolean} True if the user has at least one of the required roles; otherwise, false.
   */
  const userHasTC = () => {
    if (accepted || enableTerms === 'false' || enableTerms === undefined) {
      return true;
    }
    return false;
  };

  const handleAccept = async () => {
    const todayDate = new Date();
    const postData: TcPost = {
      tacId: tacID,
      signed: true,
      date: todayDate,
    };
    await TCServicePost(postData);
    const termsData = await TCServiceGet();
    if (termsData?.signed) {
      setAccepted(true);
    }
  };

  const handleDeny = () => {
    setIsVisible(true);
  };

  const handleSee = () => {
    setIsVisible(false);
  };

  const addNewLinesBeforeCodeBlocks = (markdownText: string): string => {
    // Regular expression to find code blocks
    const regex = /(```\w*\n[\s\S]*?\n```)/g;

    // Replace function to add new lines
    const replaceFunc = (match: string) => `\n\n${match}`;

    // Replace the matched code blocks with new lines added before them
    return markdownText.replace(regex, replaceFunc);
  };

  if (userHasTC()) {
    return <>{children}</>;
  }
  if (fallbackMessage) {
    return (
      <Box className="TC-messageStyle">
        <div className="TC-header">
          <img alt="KM Logo" src={Logo} className="TC-logoImg logo" />
          <img alt="ElastaCloud Logo" src={ECLogo} className="TC-ecLogoImg EC_logo" />
        </div>
        {isVisible && (
          <div className="decline-div-bg">
            <div className="decline-div-container">
              <Alert severity="warning" className="TC-containerStyle">
                To access the website, it is imperative that you agree to the stipulated terms and
                conditions.
                <Button onClick={handleSee} className="decline-container-button">
                  See Terms
                </Button>
              </Alert>
              <div className="TC-buttonContainerStyle" />
            </div>
          </div>
        )}
        {!isVisible && (
          <div>
            <Alert icon={false} severity="info" className="TC-containerStyle">
              <Markdown options={{ wrapper: 'article' }}>
                {addNewLinesBeforeCodeBlocks(fallbackMessage)}
              </Markdown>
            </Alert>
            <div className="TC-buttonContainerStyle">
              <Button type="button" onClick={handleDeny} className="termsDecline">
                Decline
              </Button>
              <Button type="button" onClick={handleAccept} className="termsAcceptance">
                Accept
              </Button>
            </div>
          </div>
        )}
      </Box>
    );
  }
  return null;
};

TCAcceptance.defaultProps = {
  fallbackMessage: undefined,
};

export default TCAcceptance;
