import { IFacets, ISearchBody } from 'shared/interfaces';
import { initialState } from '../../reducers/searchBody/index';

export default class SearchBodyBuilder {
  private readonly searchBody: ISearchBody;

  constructor() {
    this.searchBody = initialState;
  }

  question(query: string): SearchBodyBuilder {
    this.searchBody.query = query;
    this.searchBody.queryType = 'semantic';
    return this;
  }

  query(query: string, fields: string, type?: string): SearchBodyBuilder {
    const queryType = type !== undefined ? type : 'simple';
    this.searchBody.query = query;
    this.searchBody.searchFields = fields;
    this.searchBody.queryType = queryType;
    return this;
  }

  facets(facets: IFacets): SearchBodyBuilder {
    this.searchBody.facets = facets;
    return this;
  }

  startIndex(startIndex: number): SearchBodyBuilder {
    this.searchBody.startIndex = startIndex;
    return this;
  }

  resultsPerPage(resultsPerPage: number): SearchBodyBuilder {
    this.searchBody.resultsPerPage = resultsPerPage;
    return this;
  }

  filter(filter: string): SearchBodyBuilder {
    this.searchBody.filter = filter;
    return this;
  }

  build(): ISearchBody {
    return this.searchBody;
  }
}
