enum UserDataScopes {
  Projects = 'projects',
  Project = 'project',
  Collection = 'collection',
  File = 'file',
  Files = 'files',
  SavedQuery = 'savedquery',
  Comment = 'comment',
}

export default UserDataScopes;
