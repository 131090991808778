import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import actions from 'actions';
import {
  IProjectSavedQuery,
  IAppState, IFacetConfiguration, IFacetsConfiguration,
} from 'shared/interfaces';
import { FaAngleRight, FaSearch } from 'react-icons/fa';
import './index.css';
import SearchBodyBuilder from 'shared/builders/SearchBodyBuilder';
import { withStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { IoMdRemove } from 'react-icons/io';
import { useConfirm } from 'material-ui-confirm';
import { deleteQueryThunk } from 'reducers/projectsReducer/thunks';
import DoubleClickInput from 'shared/components/doubleclick-input';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { fieldsConfiguration } from 'fieldsConfig';
import TextCleaner from 'shared/helpers/TextCleaner';
import FacetTypes from 'shared/enums/FacetTypes';
import Button from '@material-ui/core/Button';

interface props {
  innerRef: any;
  selectQuery: (query : IProjectSavedQuery) => void;
  editQuery?: (query : IProjectSavedQuery) => void;
}

const FormattedTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

export default function queries({ innerRef, selectQuery, editQuery }: props) {
  const dispatch = useDispatch();
  const navHistory = useHistory();

  const confirm = useConfirm();

  const selectedProject = useSelector((state: IAppState) => state.projects.UIselectedProject);
  const selectedQuery = useSelector((state: IAppState) => state.projects.UIselectedQuery);
  const viewQueries = useSelector((state: IAppState) => state.projects.UIviewQueries);
  const searchFields = fieldsConfiguration;
  const facetsConfiguration = useSelector((state: IAppState) => state.facetsConfiguration);

  const getFacetsToUseOnSearch = () => {
    const splittedFacets = process.env.REACT_APP_SEARCH_FACETS !== 'false' ? process.env.REACT_APP_SEARCH_FACETS?.split(';') || [] : [];
    const facets: IFacetConfiguration[] = [];
    splittedFacets.forEach((facet) => {
      const items = facet.split(',');
      const values : any[] = [];
      items.forEach((item) => {
        values.push(item.split(':')[1].trim());
      });
      if (values.length > 0) {
        facets.push({
          key: values[0],
          valueToDisplay: values[1],
          type: values[2],
          count: Number(values[3]),
          sort: values[4].substring(0, values[4].length - 1),
        });
      }
    });
    const facetsConfig: IFacetsConfiguration = { facets };
    dispatch(actions.setFacetsConfiguration(facetsConfig));
    return facetsConfig;
  };

  const getFacetNameToDisplay = (key: string) => facetsConfiguration.facets.filter((facetConfiguration) => facetConfiguration.key === key)[0]?.valueToDisplay;

  useEffect(() => {
    if (!facetsConfiguration || facetsConfiguration.facets.length === 0) getFacetsToUseOnSearch();
  }, []);

  useEffect(() => {
    innerRef?.current?.scrollIntoView({ behavior: 'smooth', inline: 'end' });
  }, [viewQueries]);

  const handleLoadQuery = (query: any) => {
    dispatch(actions.setSearchResultHighlight({ isVisible: false, currentHighlight: '' }));
    const search = new SearchBodyBuilder();
    dispatch(
      actions.setSearchResult({
        results: [],
        facets: [],
        answers: [],
        total: 0,
        isLoading: true,
      }),
    );
    if (query.searchFields === 'semantic') {
      dispatch(actions.setTextToSearch({ text: query.query }));
      search.question(query.query);
    } else {
      dispatch(actions.setTextToSearch({ text: query.query }));
      dispatch(actions.setFieldsToSearch({ text: query.searchFields }));
      search.query(query.query, query.searchFields);
    }
    search.filter(query.filter);
    dispatch(actions.setPdfPreview({ showPdf: false }));
    dispatch(actions.setSearchBodyQuery(search.build()));

    navHistory.push({
      pathname: 'results',
      search: `toSearch=${encodeURIComponent(query.query.replaceAll('+', '|'))}&toFields=${query.searchFields.replaceAll(',', '|')}`,
    });
  };

  const handleDeleteQuery = () => {
    if (selectedProject !== undefined && selectedQuery !== undefined) {
      confirm({
        title: 'Are you sure you want to delete this saved search?', description: 'This action is permanent.', confirmationText: 'Yes', cancellationText: 'No',
      })
        .then(() => {
          dispatch(deleteQueryThunk(selectedQuery, selectedProject));
        })
        .catch(() => {});
    }
  };

  const handleSavedQueryNameChange = (event: any, value: string, savedQuery: IProjectSavedQuery) => {
    const editSavedQueryItem : IProjectSavedQuery = {
      SavedQueryName: value,
      SavedQueryId: savedQuery.SavedQueryId,
      Comment: savedQuery.Comment,
      Query: savedQuery.Query,
    };
    if (editQuery) editQuery(editSavedQueryItem);
  };

  const handleSavedQueryCommentChange = (event: any, value: string, savedQuery: IProjectSavedQuery) => {
    const editSavedQueryItem : IProjectSavedQuery = {
      SavedQueryName: savedQuery.SavedQueryName,
      SavedQueryId: savedQuery.SavedQueryId,
      Comment: value,
      Query: savedQuery.Query,
    };
    if (editQuery) editQuery(editSavedQueryItem);
  };

  const getFieldsToDisplay = (querySearch: any, queryFields: any) => {
    const searchValues = querySearch.split('+');
    const fieldsOrder = queryFields.split(',');
    const listFieldsToDisplay : any[] = [];

    fieldsOrder.forEach((field: any, index: string | number) => {
      const fieldConfig = searchFields.find((item) => item.searchField === field);
      const fieldToDisplay = {
        searchField: fieldConfig?.searchField || '',
        placeholder: fieldConfig?.placeholder,
        value: searchValues[index],
      };
      listFieldsToDisplay.push(fieldToDisplay);
    });

    return listFieldsToDisplay;
  };

  const displayQuery = (searchQuery: any) => {
    const fieldsToDisplay = getFieldsToDisplay(searchQuery.query, searchQuery.searchFields);
    return (
      <>
        {
          fieldsToDisplay.map((field, index) => (
            <span key={`${field.searchField}-${index.toString()}`}>
              <span className="textSmall">{field.placeholder}</span>: {field.value}<br />
            </span>
          ))
        }
      </>
    );
  };

  const parseODataFilter = (stringFilter: string) => {
    const filterGroups = stringFilter.split(' and ');
    const parsedFilter : any[] = [];

    if (filterGroups[0] === '') return parsedFilter;

    const rgGetGroupField = /(\()(\w+)/;
    const rgGetGroupValue = /'(?:\\.|[^'\\])*'/;

    filterGroups.forEach((filterGroup) => {
      const matchField = filterGroup.match(rgGetGroupField);
      const fieldName = matchField ? matchField[2] : '';

      const matchValue = filterGroup.match(rgGetGroupValue);
      let fieldValue = matchValue ? TextCleaner.unescapeODataFilter(matchValue[0]) : '';

      const facetGroupValuesType = facetsConfiguration.facets.filter((facet) => facet.key === fieldName)[0]?.type;

      if (facetGroupValuesType === FacetTypes.EdmDateTimeOffset) {
        const valueTrimmed = filterGroup.substring(filterGroup.indexOf('eq ') + 3).slice(0, -1);
        let newValue = new Date(valueTrimmed).toUTCString();
        newValue = newValue.substring(4, newValue.indexOf('00:00:00'));
        fieldValue = newValue;
      }

      parsedFilter.push({
        name: fieldName,
        displayName: getFacetNameToDisplay(fieldName),
        value: fieldValue,
      });
    });

    const groupedFilters = parsedFilter.reduce(
      (entryMap, e) => entryMap.set(e.displayName, [...entryMap.get(e.displayName) || [], e]),
      new Map(),
    );

    return Array.from(groupedFilters.entries());
  };

  const getFiltersToDisplay = (stringFilter: string) => {
    // eslint-disable-next-line max-len
    const filters = parseODataFilter(stringFilter);
    return filters;
  };

  const displayFilters = (stringFilter: any) => {
    const filtersToDisplay = getFiltersToDisplay(stringFilter);
    if (filtersToDisplay.length === 0) {
      return <></>;
    }
    return (
      <>
        <p><strong className="textSmall">Filters</strong><br />
          {
      filtersToDisplay.map((filter: any, index: string | number) => (
        <span key={`${filter[0]}-${index.toString()}`}>
          <span className="textSmall">{filter[0]}</span>:&nbsp;
          {
            filter[1].map((value: any, valueIndex: string | number) => (
              <span key={`${value.name}-${valueIndex.toString()}`}>
                <span>{value.value}</span>&nbsp;{Number(valueIndex) < filter[1].length - 1 && (<span>&amp;&nbsp;</span>)}
              </span>
            ))
          }<br />
        </span>
      ))
    }
        </p>
      </>
    );
  };

  return (
    <>
      {
      viewQueries && (
      <div className="queries column column-full" ref={innerRef}>
        {
      selectedProject && (
      <div className="queries-container">
        {/* <div className="queries-header"><strong>{selectedProject.name}</strong></div> */}
        <div className="queries-items">
          <div className="column">
            <div className="list">
              {selectedProject.SavedQueries && selectedProject.SavedQueries.map((item) => (
                <div
                  key={item.SavedQueryId}
                  className={`list-item ${item.SavedQueryId === selectedQuery?.SavedQueryId ? 'active' : ''}`}
                  onClick={() => selectQuery(item)}
                >
                  <DoubleClickInput
                    inputType="text"
                    inputValue={item.SavedQueryName}
                    onInputChange={(e, value) => handleSavedQueryNameChange(e, value, item)}
                  />
                  <FaAngleRight className="angle-right" />
                </div>
              ))}
            </div>
          </div>

          {selectedQuery && (
          <div className="query">

            <div className="query-header">
              <div className="query-header-title">
                <strong>
                  <DoubleClickInput
                    inputType="text"
                    inputValue={selectedQuery?.SavedQueryName}
                    onInputChange={(e, value) => handleSavedQueryNameChange(e, value, selectedQuery)}
                  />
                </strong>
              </div>
            </div>

            <div className="query-content">
              <strong className="textSmall">Description</strong>
              <DoubleClickInput
                inputType="textarea"
                inputValue={selectedQuery.Comment}
                placeholderText="Double click to add description"
                onInputChange={(e, value) => handleSavedQueryCommentChange(e, value, selectedQuery)}
              />
              <p><strong className="textSmall">Query</strong><br />
                {displayQuery(selectedQuery.Query)}
              </p>

              {displayFilters(selectedQuery.Query.filter)}

            </div>
            <div className="query-content-actions">
              <ButtonGroup color="primary" aria-label="outlined button group">
                <FormattedTooltip arrow placement="top" title="View search results">
                  <Button type="submit" onClick={() => handleLoadQuery(selectedQuery?.Query)} className="search-button">
                    <FaSearch size={16} />
                  </Button>
                </FormattedTooltip>
                <FormattedTooltip arrow placement="top" title="Delete search query">
                  <Button type="button" onClick={handleDeleteQuery}>
                    <IoMdRemove size={20} />
                  </Button>
                </FormattedTooltip>
              </ButtonGroup>
            </div>
          </div>
          )}

        </div>
      </div>
      )
    }
      </div>
      )
    }
    </>
  );
}

queries.defaultProps = {
  editQuery: null,
};
