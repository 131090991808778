import { combineReducers } from 'redux';
import toSearch from 'reducers/toSearchReducer';
import fieldsToSearch from 'reducers/fieldsToSearch';
import searchResult from 'reducers/searchResultReducer';
import searchHighlightPreview from 'reducers/searchHighlightPreview';
import pdfPreview from 'reducers/pdfPreview';
import searchBody from 'reducers/searchBody';
import facetsConfiguration from 'reducers/facetsConfiguration';
import projectsReducer from 'reducers/projectsReducer';
import folderListReducer from 'reducers/folderListReducer';
import selectFolderReducer from 'reducers/selectFolderReducer';
import toSearchAsk from './toSearchAskReducer';
import searchAskBody from './searchBodyAsk';
import searchAskResult from './searchAskResultReducer';

const Reducers = combineReducers({
  toSearch,
  toSearchChat: toSearchAsk,
  fieldsToSearch,
  searchResult,
  chatResult: searchAskResult,
  searchHighlightPreview,
  projects: projectsReducer,
  pdfPreview,
  searchBody,
  chatBody: searchAskBody,
  facetsConfiguration,
  folderList: folderListReducer,
  selectedFolder: selectFolderReducer,
});

export default Reducers;
