import { servicesConfig } from 'authConfig';
import baseFetch from 'services/baseFetch';
import { TCInterface, TcPost } from 'shared/interfaces';

export const TCServiceGet = async (): Promise<TCInterface | null> => {
  let termsServiceUrl = `${servicesConfig.kmFuncAPIEndpoint}/api/Compliance/TandC`;

  const indexName = process.env.REACT_APP_KMINDEXNAME;

  if (indexName) {
    termsServiceUrl += `/${indexName}`;
  }

  const data = await baseFetch(termsServiceUrl);

  if (data.statusCode === 404) {
    return null;
  }

  if (data.error) {
    throw new Error(`${data.errorMessage}`);
  }

  const result: TCInterface = data.data;
  return result;
};

export const TCServicePost = async (data: TcPost): Promise<void> => {
  let termsServiceUrl = `${servicesConfig.kmFuncAPIEndpoint}/api/Compliance/TandC`;

  const indexName = process.env.REACT_APP_KMINDEXNAME;

  if (indexName) {
    termsServiceUrl += `/${indexName}`;
  }

  const response = await baseFetch(termsServiceUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.error) {
    throw new Error(`HTTP error! Status: ${response.errorMessage}`);
  }
};
