import { IToSearchFields } from 'shared/interfaces';

export const initialState: IToSearchFields = {
  text: '',
};

const fieldsToSearch = (state: IToSearchFields = initialState, action: any) => {
  switch (action.type) {
    case 'SET_FIELDS_TO_SEARCH':
      return action.payload;
    default:
      return state;
  }
};

export default fieldsToSearch;
