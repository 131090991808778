import { IFolderConfiguration } from 'shared/interfaces';

export const initialState: IFolderConfiguration = {
  isLoading: true,
  error: false,
  errorMessage: '',
};

const folderList = (state: IFolderConfiguration = initialState, action: any) => {
  switch (action.type) {
    case 'SET_FOLDER_RESULT':
      return action.payload ? action.payload : initialState;
    default:
      return state;
  }
};

export default folderList;
