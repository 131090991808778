import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions';
import {
  IAppState, IProjectsCollectionDocumentComment, IRemoveBody,
} from 'shared/interfaces';
import { apiLoginRequest, servicesConfig } from 'authConfig';
import { useAccount, useMsal } from '@azure/msal-react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { useConfirm } from 'material-ui-confirm';
import { IoMdDownload, IoMdRemove } from 'react-icons/io';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/styles/withStyles';
import { FaPlus, FaTrash } from 'react-icons/fa';
import {
  addCollectionDocumentCommentThunk, deleteCollectionDocumentCommentThunk, deleteCollectionDocumentThunk, editCollectionDocumentCommentThunk,
} from 'reducers/projectsReducer/thunks';
import RemoveService from 'services/remove';
import localforage from 'localforage';
import './index.css';
import DoubleClickInput from 'shared/components/doubleclick-input';

interface props {
  viewDocument: () => void;
  setParentSnackPack: (properties: any) => void;
}

const FormattedTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

const mime = require('mime-types');

export default function collectionDocument({ viewDocument, setParentSnackPack }: props) {
  const dispatch = useDispatch();

  const { instance, accounts } = useMsal();
  const currentAccount = useAccount(accounts[0] || {});

  const getUserId = () => (currentAccount ? currentAccount.localAccountId : null);
  const [isSuper, setIsSuper] = useState(false);

  const selectedProject = useSelector((state: IAppState) => state.projects.UIselectedProject);
  const selectedCollection = useSelector((state: IAppState) => state.projects.UIselectedCollection);
  const selectedDocument = useSelector((state: IAppState) => state.projects.UIselectedDocument);

  const [newComment, setNewComment] = useState('');

  const [store, setStore] = useState<any | null>(null);

  const confirm = useConfirm();

  const handleViewDocument = () => {
    viewDocument();
  };

  const handleDownloadFile = () => {
    if (currentAccount && selectedDocument) {
      instance.acquireTokenSilent({
        ...apiLoginRequest,
        account: currentAccount,
      }).then(async (response) => {
        const fileURL = `${servicesConfig.kmFuncAPIEndpoint}/api/files/download/${selectedDocument?.storagePath}`;

        setParentSnackPack({ message: 'Downloading file', key: new Date().getTime(), severity: 'info' });

        fetch(fileURL, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        })
          .then((responseBlob) => responseBlob.blob().then((blob) => {
            let error = false;
            let errorMessage = '';
            if (responseBlob.status >= 400 && responseBlob.status < 600) {
              error = true;
              errorMessage = responseBlob.statusText;
            }

            const contentTypeRaw = responseBlob.headers.get('content-type');
            const contentTypes = contentTypeRaw?.split(';') || [];
            const contentType = contentTypes.length ? contentTypes[0] : undefined;
            const contentTypeExtension = mime.extension(contentType);

            return {
              error,
              errorMessage,
              contentTypeExtension,
              raw: blob,
            };
          }))
          .then((data) => {
            if (!data.error) {
              const re = /(?:\.([^.]+))?$/;
              let filename = selectedDocument?.name || '';
              const fileExtension = re.exec(filename);
              if (fileExtension != null && fileExtension[1] !== undefined) {
                if (fileExtension[1] !== data.contentTypeExtension) {
                  filename += `.${data.contentTypeExtension}`;
                }
              } else {
                filename += `.${data.contentTypeExtension}`;
              }
              const url = window.URL.createObjectURL(
                new Blob([data.raw]),
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                filename,
              );

              document.body.appendChild(link);
              link.click();
              link?.parentNode?.removeChild(link);
            } else {
              setParentSnackPack({
                message: 'There was a problem downloading this document. Please try again.',
                key: new Date().getTime(),
                severity: 'warning',
              });
            }
          })
          .catch();
      });
    }
  };

  const handleRemoveDocumentFromCollection = () => {
    if (selectedProject !== undefined && selectedCollection !== undefined && selectedDocument) {
      confirm({
        title: `Are you sure you want to remove this document from ${selectedCollection.CollectionName}?`,
        description: 'This action is permanent.',
        confirmationText: 'Yes',
        cancellationText: 'No',
      })
        .then(() => {
          dispatch(deleteCollectionDocumentThunk(selectedDocument, selectedCollection));
        }).catch(() => {});
    }
  };

  const handleDeleteFile = () => {
    confirm({
      title: 'Are you sure you want to delete this document?', description: 'This action is permanent.', confirmationText: 'Yes', cancellationText: 'No',
    })
      .then(() => {
        if (currentAccount) {
          setParentSnackPack(
            {
              message: 'Deleting document. Please wait.',
              key: new Date().getTime(),
              severity: 'info',
            },
          );

          const removeBody: IRemoveBody = {
            search: '',
            uniqueKeyField: 'metadata_storage_path',
            uniqueValues: [
              selectedDocument?.storagePath,
            ],
          };

          RemoveService(removeBody).then(async (data: any) => {
            if (data.error === false) {
              store.setItem(selectedDocument?.storagePath, 'removed');
              if (selectedDocument?.storagePath) dispatch(actions.projectsDocumentDeleted(selectedDocument?.storagePath));
            } else {
              setParentSnackPack({
                message: 'There was a problem deleting this document. Please try again.',
                key: new Date().getTime(),
                severity: 'warning',
              });
            }
          });
        }
      })
      .catch(() => {});
  };

  const onLoad = async () => {
    if (currentAccount) {
      instance.acquireTokenSilent({
        ...apiLoginRequest,
        account: currentAccount,
      }).then(async (response) => {
        if (currentAccount && response) {
          const claims : any = response.idTokenClaims;
          const deleteRole = process.env.REACT_APP_RBAC_DELETE_ROLE;
          const intersection = claims.roles.includes(deleteRole);

          if (intersection) {
            setIsSuper(true);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (selectedDocument) setNewComment('');
  }, [selectedDocument]);

  useEffect(() => {
    const storeInstance = localforage.createInstance({ name: 'store' });
    onLoad();
    setStore(storeInstance);
  }, []);

  const handleDeleteComment = (comment: IProjectsCollectionDocumentComment) => {
    if (selectedProject !== undefined && selectedCollection !== undefined && selectedDocument) {
      confirm({
        title: 'Are you sure you want to remove this comment?',
        description: 'This action is permanent.',
        confirmationText: 'Yes',
        cancellationText: 'No',
      })
        .then(() => {
          dispatch(deleteCollectionDocumentCommentThunk(
            comment,
            selectedDocument,
            selectedCollection,
          ));
        })
        .catch(() => {});
    }
  };

  const handleAddCommentInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { value } = e.target;

    setNewComment(value);
  };

  const handleSubmitComment = (e: any) => {
    e.preventDefault();
    if (newComment && selectedProject !== undefined && selectedCollection !== undefined && selectedDocument) {
      dispatch(addCollectionDocumentCommentThunk(
        newComment,
        selectedProject,
        selectedCollection,
        selectedDocument,
      ));
    }
  };

  const handleCommentTextChange = (event: any, value: string, comment: IProjectsCollectionDocumentComment) => {
    if (value && selectedProject !== undefined && selectedCollection !== undefined && selectedDocument) {
      const editedComment = { ...comment };
      editedComment.CommentText = value;
      dispatch(editCollectionDocumentCommentThunk(
        editedComment,
        selectedDocument,
        selectedCollection,
        selectedProject,
      ));
    }
  };

  const renderComment = (comment: IProjectsCollectionDocumentComment, index: number) => (
    <div
      className="comment"
      key={`${`${selectedDocument?.id ?? ''}-${comment.CommentId}`}-${index.toString()}`}
    >
      {/* <div className="avatar">AM</div> */}
      <div className="content">
        <div className="date">
          {comment.CommentCreateDateTime.toLocaleString()}&nbsp;
          {+comment.CommentCreateDateTime !== +comment.CommentModifiedDateTime ? `(edited on: ${comment.CommentModifiedDateTime.toLocaleString()})` : ''}
        </div>
        {/* <div className="fullName">{comment.CommentByDisplayName} wrote:</div> */}
        <div className="text">
          <DoubleClickInput inputType="text" inputValue={comment.CommentText} onInputChange={(e, value) => handleCommentTextChange(e, value, comment)} />
        </div>
      </div>
      <div className="actions">
        {getUserId() === comment.CommentUserId && (
        <Button onClick={() => handleDeleteComment(comment)}>
          <FaTrash />
        </Button>
        )}
        {/* <Button>
            <FaEdit />
          </Button> */}
      </div>
    </div>
  );

  return (

    <div className="collection-file" key={(selectedDocument?.id ?? '') + (selectedCollection?.CollectionId ?? '')}>
      {selectedDocument?.isDeleted === true && (
        <Alert variant="filled" severity="warning" style={{ marginBottom: '16px' }}>
          This document has been deleted and is no longer available
        </Alert>
      )}
      <div className="collection-file-content">
        <div className="text">
          <h3>{selectedDocument?.name}</h3>
          {
          selectedDocument?.summary && (
          <p><span className="text-small"><i>Document Summary</i></span><br />{selectedDocument?.summary}</p>
          )
          }
        </div>
        <div className="collection-file-actions">
          <div>
            {selectedDocument?.isDeleted !== true && (
              <Button className="button" type="button" onClick={handleViewDocument}>
                VIEW
              </Button>
            )}
            {selectedDocument?.isDeleted !== true && (
              <FormattedTooltip arrow placement="top" title="Download document">
                <div>
                  <Button className="button" type="button" onClick={handleDownloadFile}>
                    <div>
                      <IoMdDownload />
                    </div>
                  </Button>
                </div>
              </FormattedTooltip>
            )}

            <FormattedTooltip arrow placement="top" title="Remove document from collection">
              <div>
                <Button className="button" type="button" onClick={handleRemoveDocumentFromCollection}>
                  <div>
                    <IoMdRemove />
                  </div>
                </Button>
              </div>
            </FormattedTooltip>

            {isSuper && selectedDocument?.isDeleted !== true && (
              <FormattedTooltip arrow placement="top" title="Delete document">
                <div>
                  <Button className="button delete" type="button" onClick={handleDeleteFile} disabled={!isSuper}>
                    <div>
                      <FaTrash />
                    </div>
                  </Button>
                </div>
              </FormattedTooltip>
            )}
          </div>
        </div>
      </div>
      <div className="comments-container">
        <p>Comments
          {selectedDocument?.comments && selectedDocument?.comments.length > 0 ? ` (${selectedDocument?.comments.length})` : ''}
        </p>
        <div className="add-comment">
          <form className="single-input" onSubmit={handleSubmitComment}>
            <input className="new-comment" type="text" placeholder="add new comment" value={newComment} onChange={(e) => handleAddCommentInputChange(e)} />
            <button type="submit" className="plus" aria-label="submit comment">
              <FaPlus size={25} />
            </button>
          </form>
        </div>
        <div className="comments-list">
          {selectedDocument?.comments && (
            selectedDocument?.comments.sort(
              (
                a: IProjectsCollectionDocumentComment,
                b: IProjectsCollectionDocumentComment,
              ) => b.CommentCreateDateTime.getTime() - a.CommentCreateDateTime.getTime(),
            ).map((comment: IProjectsCollectionDocumentComment, index: number) => renderComment(comment, index)))}
        </div>
      </div>
    </div>
  );
}
